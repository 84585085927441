<template>
    <footer class="bg-gray-900" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="bg-gray-800">
            <div class="mx-auto max-w-7xl overflow-hidden px-4 py-8 sm:px-6 lg:px-8">
                <nav class="flex flex-wrap justify-center" aria-label="Footer">
                    <div class="px-5 py-2" v-for="item in navigation.company" :key="item.name">
                        <Link
                            :href="item.href"
                            class="rounded-md px-3 py-2 text-sm font-medium text-gray-200 hover:bg-white/[.1] hover:text-white"
                        >
                            {{ item.name }}
                        </Link>
                    </div>
                </nav>
            </div>
        </div>

        <div class="mt-8 flex justify-center space-x-6">
            <a
                v-for="social in navigation.social"
                :key="social.name"
                :href="social.href"
                target="_blank"
                class="rounded-full bg-white/[.1] p-2 text-gray-400 hover:text-gray-200"
            >
                <span class="sr-only">{{ social.name }}</span>
                <component :is="social.icon" class="h-6 w-6" />
            </a>
        </div>

        <div class="mx-auto max-w-3xl space-y-4 px-6 py-12 text-center">
            <img class="mx-auto h-16" src="../../../images/clawmee-logo.svg" alt="Company name" />
            <p class="text-sm leading-6 text-gray-300">
                {{ $t('homepage.description') }}
            </p>

            <nav class="flex flex-wrap justify-center pt-4">
                <div class="px-5 py-2" v-for="item in navigation.legal" :key="item.name">
                    <Link
                        :href="item.href"
                        class="rounded-md px-3 py-2 text-sm font-medium text-gray-200 hover:bg-white/[.1] hover:text-white"
                    >
                        {{ item.name }}
                    </Link>
                </div>
            </nav>
        </div>

        <div class="mx-auto max-w-7xl overflow-hidden px-4 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between border-t border-white/10 py-4 sm:py-6">
                <p class="text-sm leading-5 text-gray-400">{{ copyright }}</p>

                <a
                    :href="app.locales.href"
                    class="rounded-md text-center text-sm font-medium text-gray-200 transition hover:bg-white/[.1]"
                >
                    {{ app.locales.label }}
                </a>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import { translate as t } from '@/i18n';
import { FacebookTag, Instagram, TikTok } from '@iconoir/vue';

const app = computed(() => usePage().props.app);
const year = new Date().getFullYear();
const copyright = `© ${import.meta.env.VITE_APP_NAME} ${year}`;

const getAnchorLink = (anchor) => (route().current('home') ? anchor : route('home') + anchor);

const navigation = {
    company: [
        { name: t('app.about_us'), href: getAnchorLink('#about-us') },
        { name: t('app.find_us'), href: route('find-us.index') },
        { name: t('app.how_to_play'), href: getAnchorLink('#how-to-play') },
        { name: t('app.faq'), href: getAnchorLink('#faq') },
        { name: t('app.contact_us'), href: route('contact-us.create') },
        // { name: t('app.franchise'), href: '#' },
    ],
    legal: [
        { name: t('app.legal.privacy_policy'), href: route('legal.privacy') },
        { name: t('app.legal.terms_of_service'), href: route('legal.terms') },
    ],
    social: [
        { name: 'Facebook', href: import.meta.env.VITE_FACEBOOK_PAGE, icon: FacebookTag },
        { name: 'Instagram', href: import.meta.env.VITE_INSTAGRAM_PAGE, icon: Instagram },
        { name: 'TikTok', href: import.meta.env.VITE_TIKTOK_PAGE, icon: TikTok },
    ],
};
</script>
