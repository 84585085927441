<template>
    <!-- Top navigation -->
    <div class="bg-gray-900">
        <div class="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
            <a :href="app.locales.href" class="text-sm font-medium text-white hover:text-gray-100">
                {{ app.locales.label }}
            </a>

            <div class="flex items-center space-x-6">
                <template v-if="auth?.user">
                    <Link :href="route('me')" class="text-sm font-medium text-white hover:text-gray-100">
                        {{ $t('auth.my_account') }}
                    </Link>
                </template>
                <template v-else>
                    <Link :href="route('login')" class="text-sm font-medium text-white hover:text-gray-100">
                        {{ $t('auth.login') }}
                    </Link>
                    <Link :href="route('register')" class="text-sm font-medium text-white hover:text-gray-100">
                        {{ $t('auth.register') }}
                    </Link>
                </template>
            </div>
        </div>
    </div>

    <!-- Secondary navigation -->
    <Disclosure as="nav" class="sticky top-0 z-10 bg-white shadow" v-slot="{ open }">
        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div class="relative flex h-16 justify-between">
                <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
                    <!-- Mobile menu button -->
                    <DisclosureButton
                        class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                    >
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div>
                <div class="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
                    <a :href="route('home')" class="flex flex-shrink-0 items-center">
                        <img class="h-8 w-auto" src="../../../images/clawmee-logo.svg" />
                    </a>
                    <div class="hidden md:ml-6 md:flex md:space-x-8">
                        <Link
                            v-for="item in navigation"
                            :key="item.name"
                            :href="item.href"
                            :class="[
                                item.current
                                    ? 'border-primary-500 text-gray-900'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
                            ]"
                            :aria-current="item.current ? 'page' : undefined"
                        >
                            {{ item.name }}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <DisclosurePanel class="sm:hidden">
            <div class="space-y-1 pb-4 pt-2">
                <DisclosureButton
                    v-for="item in navigation"
                    :key="item.name"
                    as="a"
                    :href="item.href"
                    :class="[
                        item.current
                            ? 'border-primary-500 bg-primary-50 text-primary-500 hover:text-primary-700'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700',
                        'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                    ]"
                    :aria-current="item.current ? 'page' : undefined"
                >
                    {{ item.name }}
                </DisclosureButton>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>
<script setup>
import { computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { translate as t } from '@/i18n';

const app = computed(() => usePage().props.app);
const auth = computed(() => usePage().props.auth);

const getAnchorLink = (anchor) => (route().current('home') ? anchor : route('home') + anchor);

const navigation = [
    { name: t('app.about_us'), href: getAnchorLink('#about-us'), current: false },
    { name: t('app.find_us'), href: route('find-us.index'), current: route().current('find-us.*') },
    { name: t('app.how_to_play'), href: getAnchorLink('#how-to-play'), current: false },
    { name: t('app.faq'), href: getAnchorLink('#faq'), current: false },
    { name: t('app.contact_us'), href: route('contact-us.create'), current: route().current('contact-us.*') },
    // { name: t('app.franchise'), href: '#', current: route().current('franchise') },
];
</script>
